import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, useMemo, useState } from 'react';

import { Button as EditorButton } from '~components/generic/editor/Button/Button';
import { FormElementTextarea } from '~components/generic/editor/FormElementTextarea';
import { PaperPlaneIcon } from '~components/icons';
import { ChangelogType } from '~extension/changelog/model/ChangelogType';
import useLabels from '~hooks/useLabels';
import usePublishing from './usePublishing';
import styles from './PublishDialog.module.css';

type PublishDialogProps = {
  open: boolean;
  onClose?: () => void;
};

export function PublishDialog({ open, onClose }: PublishDialogProps) {
  const {
    msgPublishDialog,
    hlPublishDialog,
    btnPublishDialogCancel,
    btnPublishDialogConfirm,
    placeholderPublishDialogCustomMessage,
    msgPublishOptionUpdate,
    hlPublishOptionUpdate,
    msgPublishOptionNew,
    hlPublishOptionNew,
    msgPublishOptionNone,
    hlPublishOptionNone,
  } = useLabels([
    'hlPublishDialog',
    'msgPublishDialog',
    'btnPublishDialogCancel',
    'btnPublishDialogConfirm',
    'placeholderPublishDialogCustomMessage',
    'msgPublishOptionUpdate',
    'hlPublishOptionUpdate',
    'msgPublishOptionNew',
    'hlPublishOptionNew',
    'msgPublishOptionNone',
    'hlPublishOptionNone',
  ]);

  const { onPublish } = usePublishing();
  const [selectedType, setSelectedType] = useState<ChangelogType>(null);
  const [customMessage, setCustomMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const options = useMemo(
    () => [
      {
        type: ChangelogType.NONE,
        name: hlPublishOptionNone,
        description: msgPublishOptionNone,
      },
      {
        type: ChangelogType.NEW,
        name: hlPublishOptionNew,
        description: msgPublishOptionNew,
      },
      {
        type: ChangelogType.UPDATE,
        name: hlPublishOptionUpdate,
        description: msgPublishOptionUpdate,
      },
    ],
    [
      hlPublishOptionNew,
      hlPublishOptionNone,
      hlPublishOptionUpdate,
      msgPublishOptionNew,
      msgPublishOptionNone,
      msgPublishOptionUpdate,
    ],
  );

  const isTextareaEnabled = selectedType === ChangelogType.NEW || selectedType === ChangelogType.UPDATE;
  const isConfirmBtnDisabled = selectedType === null;
  const canConfirm =
    selectedType === ChangelogType.NONE ||
    ((selectedType === ChangelogType.NEW || selectedType === ChangelogType.UPDATE) && customMessage.length >= 3);

  function closeModal() {
    if (!isProcessing) {
      onClose?.();
    }
  }

  function handleConfirm() {
    async function doConfirm(msg: string) {
      setIsProcessing(true);
      const success = await onPublish({
        msg,
        type: selectedType,
      });

      if (success) {
        onClose?.();
        setIsProcessing(false);
      }
    }
    if (canConfirm) {
      void doConfirm(customMessage || '');
    }
  }

  function handleOnTextareaChange(value: string) {
    if (isTextareaEnabled) {
      setCustomMessage(value);
    }
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog onClose={closeModal} className="publish-dialog">
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Backdrop className={clsx(styles.overlay, 'publish-dialog--overlay', 'fixed inset-0 z-50')} />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
              key={'dialog-prepare-publishing'}
            >
              {isProcessing ? (
                <div className="inline-block w-32 max-w-md h-32 p-8 align-middle transition-all transform bg-white shadow-xl rounded">
                  <div className="absolute inset-0 flex items-center justify-center cursor-default pointer-events-none">
                    <div className="inline-block w-16 h-16 border-2 rounded-full border-gray border-solid" />
                  </div>
                  <div className="absolute inset-0 flex items-center justify-center cursor-default pointer-events-none">
                    <div
                      className="animate-spin inline-block w-16 h-16 border-2 rounded-full border-edit border-solid"
                      style={{ borderTopColor: 'transparent' }}
                      role="status"
                    />
                  </div>
                  <div className="absolute inset-0 flex items-center justify-center cursor-default pointer-events-none">
                    <div className="animate-bounce inline-block w-8 h-8 mt-2">
                      <PaperPlaneIcon />
                    </div>
                  </div>
                </div>
              ) : (
                <Dialog.Panel
                  className={clsx(
                    'publish-dialog--panel',
                    'inline-block w-full max-w-md p-4 my-4 bg-white overflow-hidden text-left align-middle transition-all transform shadow-xl rounded',
                  )}
                >
                  <Dialog.Title as="h3" className={clsx('publish-dialog--title', 'mt-0')}>
                    {hlPublishDialog}
                  </Dialog.Title>

                  <Dialog.Description as="div" className={clsx('publish-dialog--description', 'leading-normal')}>
                    {msgPublishDialog}
                  </Dialog.Description>

                  <div className={clsx('publish-dialog--content', 'mt-4')}>
                    <RadioGroup value={selectedType} onChange={setSelectedType}>
                      <RadioGroup.Label className="sr-only">Changelog</RadioGroup.Label>
                      <div>
                        {options.map(option => (
                          <RadioGroup.Option
                            key={option.type}
                            value={option.type}
                            className={({ checked }) =>
                              `${styles.option}
                        ${checked ? 'border-edit' : 'border-transparent opacity-70'}
                        bg-gray relative border-2 border-solid rounded-lg shadow-md cursor-pointer`
                            }
                          >
                            <div className="block w-full">
                              <RadioGroup.Label as="div" className="font-bold">
                                {option.name}
                              </RadioGroup.Label>
                              <RadioGroup.Description as="div" className="leading-normal mt-1">
                                {option.description}
                              </RadioGroup.Description>
                            </div>
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>

                    <div className={clsx('mt-4 transition-all', !isTextareaEnabled && 'opacity-0')}>
                      <FormElementTextarea
                        value={customMessage}
                        onChange={handleOnTextareaChange}
                        label={placeholderPublishDialogCustomMessage}
                        controls={[]}
                      />
                    </div>
                  </div>

                  <div className={clsx('publish-dialog--actions', 'mt-4 flex')}>
                    <EditorButton
                      label={btnPublishDialogConfirm}
                      icon={<PaperPlaneIcon />}
                      onClick={handleConfirm}
                      disabled={isConfirmBtnDisabled}
                    />
                    <div className="mx-2" />
                    <EditorButton label={btnPublishDialogCancel} onClick={closeModal} />
                  </div>
                </Dialog.Panel>
              )}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
