import { any, array, boolean, coerce, defaulted, is, nullable, number, optional, string, type } from 'superstruct';

import { DEFAULT_MEDIA_LIBRARY_REALM } from '$const';
import { LanguageValidator } from './Langauge';
import { SiteAccessValidator } from './SiteAccess';
import { TrackingClientsValidator } from './TrackingClient';

export const ConfigValidator = type({
  adminModeDisabled: defaulted(boolean(), false),
  apiPrefix: string(),
  appDocTitle: string(),
  appName: string(),
  backendUrl: string(),
  cssUrl: string(),
  customJs: optional(nullable(string())),
  defaultLanguage: string(),
  defaultPageUserGroups: optional(nullable(string())),
  fileBackendUrl: defaulted(string(), ''),
  googleAnalyticsID: optional(string()),
  languages: array(LanguageValidator),
  logoUrl: string(),
  matomoSiteId: defaulted(
    coerce(number(), string(), value => parseInt(value) || 0),
    0,
  ),
  matomoUrl: optional(string()),
  notificationTimeout: defaulted(number(), 6000),
  nodeCache: optional(any()),
  pagePrefix: defaulted(string(), ''),
  publicMode: coerce(boolean(), number(), value => Boolean(value)),
  symfonyBackendUrl: string(),
  myFilesEnabled: boolean(),
  myFilesUrl: defaulted(string(), ''),
  current_siteaccess: optional(SiteAccessValidator),
  tracking_clients: optional(TrackingClientsValidator),
  frontPage: defaulted(string(), '/'),
  fallbackForbiddenUrl: defaulted(nullable(string()), null),
  defaultMediaLibraryRealm: defaulted(string(), DEFAULT_MEDIA_LIBRARY_REALM),
  isMediaLibraryMaintenance: defaulted(
    coerce(nullable(boolean()), number(), value => Boolean(value)),
    false,
  ),
  logoutUrl: defaulted(string(), '/user/logout'),
});
export const isAppConfig = (obj: unknown) => is(obj, ConfigValidator);
